@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply font-light;
}

[x-cloak] { display: none !important; }

.container.default {
    @apply max-w-screen-md px-4 mx-auto;
}

.content-section {
    @apply py-8;
}

a {
    @apply font-bold;
    @apply underline;
    @apply text-rose-700 hover:text-red-500;
    & * {
        @apply italic hover:underline;
    }
}

table.teadance-program {
    @apply mb-4;
    @apply w-full;
    & tr {
        @apply border border-b-stone-200 border-x-0 border-t-0 last:border-t-0 last:border-b;
    }
    & td {
        @apply align-top;
        @apply pt-2 pr-2 last:pr-0;
    }
    & table {
        @apply -mt-2;
    }
}